<template>
  <div class="j-dynamic-content">
    <div class="d-flex align-center">
      <general-text
        v-if="!subtitle"
        :text-types="TEXT_TYPES.title"
        :text="title"
        :class="[
          'j-text',
          'j-text--white',
          isMobile ? 'j-text--fs-24' : 'j-text--fs-32',
        ]"
      />
      <div v-else-if="subtitle && !isMobile">
        <general-text
          :text="title"
          class="j-text j-text--white j-text--fs-16 font-weight-medium"
        />
        <general-text
          :text="subtitle"
          class="j-text"
        />
      </div>
      <template v-if="!isMobile">
        <v-spacer />
        <v-btn
          v-if="showMoreBtnLink"
          class="j-btn text-none text-body-1 px-5"
          :to="useLocalePrefixForHref(showMoreBtnLink)"
          :color="scssVariables.jBtnRegular"
          variant="flat"
          border
          :ripple="false"
          size="large"
        >
          {{ $t('general_show_more') }}
        </v-btn>
        <v-btn
          class="j-btn j-btn--slider px-0 ml-4"
          variant="flat"
          size="large"
          min-width="auto"
          :width="44"
          :color="scssVariables.jBtnRegular"
          :disabled="disablePrevBtn"
          :ripple="false"
          @click="goToPrevCarouselItem"
        >
          <v-icon
            :size="30"
            icon="mdi-chevron-left"
            :color="disablePrevBtn ? scssVariables.jTextSecondary : ''"
          />
        </v-btn>
        <v-btn
          class="j-btn j-btn--slider px-0 ml-1"
          variant="flat"
          size="large"
          min-width="auto"
          :width="44"
          :color="scssVariables.jBtnRegular"
          :disabled="disableNextBtn"
          :loading="loading"
          :ripple="false"
          @click="goToNextCarouselItem"
        >
          <v-icon
            :size="30"
            icon="mdi-chevron-right"
            :color="disableNextBtn ? scssVariables.jTextSecondary : ''"
          />
        </v-btn>
      </template>
    </div>
    <v-carousel
      v-if="items?.length"
      v-model="activeCarouselItem"
      ref="jVCarousel"
      :show-arrows="false"
      hide-delimiters
      :height="getCarouselHeight"
      class="mt-4 mt-lg-8"
    >
      <v-carousel-item
        v-for="(itemContent, index) of items"
        :key="index"
      >
        <general-content-items-grid
          :items="(itemContent as Array<unknown>)"
          :remote-storage-host="remoteStorageHost"
          :public-img-store-dir-name="publicImgStoreDirName"
          :column-count="columnCount"
          :column-gap="columnGap"
          :allowed-mode="allowedMode"
        />
      </v-carousel-item>
    </v-carousel>
    <general-text
      v-if="!items?.length"
      :text-types="TEXT_TYPES.title"
      :text="noItemsText!"
      class="d-flex justify-center mt-16"
    />
    <div
      v-if="isMobile"
      class="d-flex align-center mt-4"
    >
      <v-btn
        v-if="showMoreBtnLink"
        class="j-btn text-none text-body-1 px-5"
        :to="useLocalePrefixForHref(showMoreBtnLink)"
        :color="scssVariables.jBtnRegular"
        variant="flat"
        size="large"
        :ripple="false"
        border
      >
        {{ $t('general_show_more') }}
      </v-btn>
      <div v-if="subtitle">
        <general-text
          :text="title"
          class="j-text j-text--white j-text--fs-16 font-weight-medium"
        />
        <general-text
          :text="subtitle"
          class="j-text"
        />
      </div>
      <v-spacer />
      <v-btn
        class="j-btn j-btn--slider px-0 ml-4"
        variant="flat"
        size="large"
        min-width="auto"
        :width="44"
        :color="scssVariables.jBtnRegular"
        :disabled="disablePrevBtn"
        :ripple="false"
        @click="goToPrevCarouselItem"
      >
        <v-icon
          :size="30"
          icon="mdi-chevron-left"
          :color="disablePrevBtn ? scssVariables.jTextSecondary : ''"
        />
      </v-btn>
      <v-btn
        class="j-btn j-btn--slider px-0 ml-1"
        variant="flat"
        size="large"
        min-width="auto"
        :width="44"
        :color="scssVariables.jBtnRegular"
        :disabled="disableNextBtn"
        :loading="loading"
        :ripple="false"
        @click="goToNextCarouselItem"
      >
        <v-icon
          :size="30"
          icon="mdi-chevron-right"
          :color="disableNextBtn ? scssVariables.jTextSecondary : ''"
        />
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import { VCarousel } from 'vuetify/lib/components/index.mjs';
import { TEXT_TYPES } from '~/constants/general';
import type { AllowedMode } from '~/types/components/General/ContentGameCard';

const props = defineProps({
  items: {
    type: Array,
    default: () => [],
    required: false,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  noItemsText: {
    type: String,
    default: '',
  },
  showMoreBtnLink: {
    type: String,
    default: '',
  },
  loading: {
    type: Boolean,
    default: false,
  },
  remoteStorageHost: {
    type: String,
    default: '',
  },
  publicImgStoreDirName: {
    type: String,
    default: '',
  },
  columnCount: {
    type: Number,
    default: 0,
  },
  columnGap: {
    type: Number,
    default: 0,
  },
  chunkSize: {
    type: Number,
    default: 0,
  },
  allowedMode: {
    type: String as () => AllowedMode,
    default: undefined as AllowedMode,
  },
});
const {
  items,
  loading,
  title,
  showMoreBtnLink,
  noItemsText,
  remoteStorageHost,
  columnCount,
  columnGap,
  chunkSize,
  publicImgStoreDirName,
} = toRefs(props);
let greaterShownSlideIndex = 0;
const emits = defineEmits(['go-to-next-carousel-item']);
const activeCarouselItem = ref(0);
const goToNextCarouselItem = () => {
  activeCarouselItem.value = Math.min(activeCarouselItem.value + 1, items.value.length - 1);
  if (activeCarouselItem.value > greaterShownSlideIndex) {
    greaterShownSlideIndex = activeCarouselItem.value;
    emits('go-to-next-carousel-item', activeCarouselItem.value);
  }
};
const reset = () => {
  activeCarouselItem.value = 0;
  greaterShownSlideIndex = 0;
};
const goToPrevCarouselItem = () => {
  activeCarouselItem.value = Math.max(activeCarouselItem.value - 1, 0);
};
const isMobile = inject<Ref<boolean>>('isMobile');
const disablePrevBtn = computed(() => !Boolean(activeCarouselItem.value));
const disableNextBtn = computed(() =>
  !Boolean(items.value.length) ||
  (activeCarouselItem.value === items.value.length - 1),
);
const scssVariables = useScssVariables();
const isGameItems = computed(() => publicImgStoreDirName.value === 'games');
const jVCarousel: Ref<null | VCarousel> = ref(null);
const contentCardHeight = ref(0);
const getCarouselHeight = computed(() => {
  const rowCount = Math.ceil(chunkSize.value / columnCount.value);
  const gapsHeight = (rowCount - 1) * columnGap.value;
  const contentCardsHeight = rowCount * contentCardHeight.value;

  return contentCardHeight.value
   ? (gapsHeight + contentCardsHeight)
   : 'auto';
});
const updateCarouselHeight = () => {
  if (chunkSize.value) {
    const contentCard = isGameItems.value
      ? '.j-content-game-card'
      : '.j-content-card';

    const checkJContentGameCardHeight = setInterval(() => {
      const currentContentCards = jVCarousel.value?.$el.querySelector('.v-window-item--active');
      const currentContentCard = currentContentCards?.querySelector(contentCard);
      const percentageHeightFromCardWidth = 65.8192;

      if (currentContentCard) {
        contentCardHeight.value = currentContentCard.offsetWidth * (percentageHeightFromCardWidth / 100);
      }

      if (contentCardHeight.value > 0) {
        [...currentContentCards.querySelector('.j-content-items-grid__content').children].forEach((item) => {
          item.querySelector(contentCard).style.minHeight = `${contentCardHeight.value}px`;
        });
        clearInterval(checkJContentGameCardHeight);
      }
    }, 500);
  }
};

onMounted(() => {
  nextTick(() => {
    updateCarouselHeight();
    window.addEventListener('resize', updateCarouselHeight);
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', updateCarouselHeight);
});

defineExpose({ reset });
</script>
